<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3 pt-3">
        A TLC plate was prepared and the R<sub>f</sub> value for the compound of interest was found
        to be 0.24. If you want the R<sub>f</sub> value to be closer to 0.63, which of the following
        could you do?
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-n6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uci51LCPrelab1Q7',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {
          text: 'Use a more concentrated sample',
          value: 'moreConcentrated',
        },
        {
          text: "Don't allow the solvent front to travel as far",
          value: 'solventFrontCloser',
        },
        {
          text: 'Make the eluent more polar',
          value: 'morePolarEluent',
        },
        {
          text: 'Make the eluent less polar',
          value: 'lessPolarEluent',
        },
        {
          text: 'Spot the compound further up from the bottom of the plate',
          value: 'spotHigherUp',
        },
      ],
    };
  },
};
</script>
